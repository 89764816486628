import { useContext, useEffect } from "react";
import { Languages } from "../../hooks/useI18n";
import i18n from "i18next";
import { Select, Widget } from "./LanguageWidget.styles";
import { IconSelectArrow } from "../Icons";
import { I18N_KEY, TestId, metricEvents } from "../../utils";
import { useHeap } from "../../hooks";
import { I18nContext } from "../../contexts";

const LANGUAGES: { locale: Languages; label: string }[] = [
  { locale: Languages.en, label: "English" },
  { locale: Languages.es, label: "Español" },
  { locale: Languages.de, label: "Deutsch" },
  { locale: Languages.fr, label: "Français (FR)" },
  { locale: Languages.frCA, label: "Français (CA)" },
  { locale: Languages.it, label: "Italiano" },
  { locale: Languages.ja, label: "日本語" },
];

const LanguageWidget = () => {
  const { track } = useHeap();
  const { locationLanguage, needsFallback, selectedLng, setSelectedLng } =
    useContext(I18nContext);

  const changeLanguage = (value: Languages) => {
    i18n.changeLanguage(value);
    track(metricEvents.changeLanguage, {
      language: value,
      previousLanguage: selectedLng,
      locationLanguage,
    });
    setSelectedLng(value);
    localStorage.setItem(I18N_KEY, value);
  };

  useEffect(() => {
    setSelectedLng((i18n.resolvedLanguage as Languages) || Languages.en);
  }, [setSelectedLng]);

  useEffect(() => {
    if (needsFallback && locationLanguage) {
      i18n.changeLanguage(locationLanguage).then(() => {
        setSelectedLng(i18n.resolvedLanguage as Languages);
        track(metricEvents.initialLanguage, {
          language: i18n.resolvedLanguage,
          source: "property_fallback",
        });
      });
    }
  }, [locationLanguage, needsFallback, track, setSelectedLng]);

  return (
    <Widget>
      <Select
        data-testid={TestId.LanguageSelect}
        value={selectedLng}
        title="Select language"
        aria-label="Select language"
        onChange={(e) => changeLanguage(e.target.value as Languages)}
      >
        {LANGUAGES.map((lng, key) => (
          <option key={key} value={lng.locale}>
            {lng.label}
          </option>
        ))}
      </Select>
      <IconSelectArrow />
    </Widget>
  );
};

export default LanguageWidget;
