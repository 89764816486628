import styled from "styled-components";

export const Search = styled.section`
  border-bottom: 1px solid ${({ theme }) => theme.tertiary500};
  padding: 24px;
  padding-top: 16px;
`;

export const ResidentDirectoryText = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  margin: 0px;
  padding: 24px;
  padding-bottom: 8px;
  border-bottom: 1px solid ${({ theme }) => theme.tertiary500};
  background-color: ${({ theme }) => theme.neutral50};
  display: flex;
  justify-content: space-between;

  > a {
    margin-top: 0px;
  }
`;

export const NoResult = styled.p`
  font-weight: 300;
  font-size: 12px;
  line-height: 18px;
  margin: 0px;
  padding: 24px;
  padding-top: 8px;
  border-bottom: 1px solid ${({ theme }) => theme.tertiary500};
  background-color: ${({ theme }) => theme.neutral50};
`;

export const TermsOfService = styled.p`
  font-weight: 300;
  font-size: 12px;
  line-height: 18px;
  margin-top: 8px;
  margin-bottom: 0px;

  > a {
    color: ${({ theme }) => theme.primary};
  }
`;

export const NoResidents = styled.p`
  font-weight: 300;
  font-size: 12px;
  line-height: 18px;
  margin: 16px 24px;
`;

export const ModalTitle = styled.p`
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  text-align: center;
`;

export const ModalBtns = styled.div`
  display: flex;
  flex-direction: column;

  > button:first-child {
    margin-bottom: 12px;
  }
`;
