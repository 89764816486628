import { useEffect, useState } from "react";
import { ControlState } from "../../pages/LiveView/LiveView";
import { NEUTRAL_200, NEUTRAL_500, WHITE } from "../../styles/colors";
import {
  IconCamera,
  IconCameraDisabled,
  IconDisabledMicrophone,
  IconMicrophone,
  IconPhoneDown,
  IconRotate,
} from "../Icons";
import {
  ControlsSectionDiv,
  ControlButton,
  ControlsDiv,
} from "./ControlsSection.styles";
import { TestId } from "../../utils";

export type ControlsSectionProps = {
  onDisconnect: () => void;
  handleRotate: () => void;
  handleCamera: () => void;
  handleMic: () => void;
  endInProgress: boolean;
  cameraControl?: ControlState;
  micControl?: ControlState;
  withPoorConnection?: boolean;
};

const ControlsSection = ({
  onDisconnect,
  handleRotate,
  handleCamera,
  handleMic,
  endInProgress,
  cameraControl,
  micControl,
  withPoorConnection,
}: ControlsSectionProps) => {
  const [hasOneCamera, setHasOneCamera] = useState(false);

  useEffect(() => {
    const checkDevices = async () => {
      const devices = await navigator.mediaDevices.enumerateDevices();
      if (devices) {
        const videoInputs = devices.filter(
          (item) => item.kind === "videoinput"
        );
        setHasOneCamera(videoInputs.length <= 1);
      }
    };
    checkDevices();
  }, []);

  const rotateUnavailable = () => {
    return (
      cameraControl === ControlState.Unavailable ||
      hasOneCamera ||
      cameraControl === ControlState.Disabled
    );
  };

  const getIconColor = (type: "rotate" | "camera" | "microphone") => {
    if (withPoorConnection) {
      return NEUTRAL_500;
    }

    switch (type) {
      case "rotate":
        return rotateUnavailable() ? NEUTRAL_200 : NEUTRAL_500;
      case "camera":
        return cameraControl === ControlState.Unavailable ? NEUTRAL_200 : WHITE;
      case "microphone":
        return WHITE;
    }
  };

  return (
    <ControlsSectionDiv>
      <ControlButton
        isDisabled={endInProgress}
        data-testid={TestId.LiveViewControlsDisconnect}
        title="Disconnect"
        onClick={onDisconnect}
        danger
      >
        <IconPhoneDown />
      </ControlButton>
      <ControlsDiv data-testid={TestId.LiveViewControls}>
        <ControlButton
          withPoorConnection={withPoorConnection}
          unavailable={rotateUnavailable()}
          onClick={() => {
            if (rotateUnavailable()) {
              return;
            }

            handleRotate();
          }}
          data-testid={TestId.LiveViewControlsRotate}
          title="Rotate"
        >
          <IconRotate fill={getIconColor("rotate")} />
        </ControlButton>

        <ControlButton
          isDisabled={cameraControl === ControlState.Disabled}
          unavailable={cameraControl === ControlState.Unavailable}
          onClick={handleCamera}
          data-testid={TestId.LiveViewControlsCamera}
          title="turn camera off"
          withPoorConnection={withPoorConnection}
        >
          {cameraControl === ControlState.Enabled ? (
            <IconCamera />
          ) : (
            <IconCameraDisabled
              width={24}
              height={24}
              fill={getIconColor("camera")}
            />
          )}
        </ControlButton>

        <ControlButton
          isDisabled={micControl === ControlState.Disabled}
          onClick={handleMic}
          data-testid={TestId.LiveViewControlsMic}
          title="toggle mic on/off"
          withPoorConnection={withPoorConnection}
        >
          {micControl === ControlState.Enabled ? (
            <IconMicrophone data-testid={TestId.LiveViewControlsMicEnabled} />
          ) : (
            <IconDisabledMicrophone
              fill={getIconColor("microphone")}
              data-testid={TestId.LiveViewControlsMicDisabled}
            />
          )}
        </ControlButton>
      </ControlsDiv>
    </ControlsSectionDiv>
  );
};

export default ControlsSection;
