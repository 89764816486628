import styled from "styled-components";

export const Location = styled.section`
  padding: 28px;
  background: ${({ theme }) => theme.white};
  padding-bottom: 0px;
`;

export const LocationTitle = styled.h1`
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  margin-top: 12px;
  margin-bottom: 8px;
`;

export const Details = styled.span`
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
`;
