import { useTranslation } from "react-i18next";

import { IconCameraDisabled } from "../Icons";
import {
  CallingBox,
  CallingUser,
  WebCamOverlay,
  UserData,
  CameraDisabledBox,
} from "./Calling.styles";
import { getInitials } from "../../utils/stringUtils";
import { ControlState, VideoConstraints } from "../../pages/LiveView/LiveView";
import { GuestMedia, UserBox } from "../";

type CallingProps = {
  displayName: string;
  constraints: VideoConstraints;
  guestMedia?: MediaStream;
  cameraControl?: ControlState;
};

const Calling = ({
  displayName,
  constraints,
  cameraControl,
  guestMedia,
}: CallingProps) => {
  const { t } = useTranslation();

  return (
    <CallingBox>
      <>
        <GuestMedia
          fullHeight
          videoConstraints={constraints}
          guestMedia={guestMedia}
          state={cameraControl}
        />

        <WebCamOverlay
          camUnavailable={cameraControl === ControlState.Unavailable}
          camDisabled={cameraControl === ControlState.Disabled}
        >
          <CallingUser>
            <UserBox isSmall initials={getInitials(displayName)}></UserBox>
            <UserData>
              <h1>{displayName}</h1>
              <span>
                {cameraControl === ControlState.Enabled
                  ? t("live_view.video_call")
                  : t("live_view.audio_call")}
              </span>
            </UserData>
          </CallingUser>

          {cameraControl === ControlState.Unavailable && (
            <CameraDisabledBox>
              <IconCameraDisabled />
              <p>{t("live_view.camera_required")}</p>
            </CameraDisabledBox>
          )}
        </WebCamOverlay>
      </>
    </CallingBox>
  );
};

export default Calling;
