import styled from "styled-components";

export const TryingToReconnectDiv = styled.section`
  width: 100%;
  background: #000000a6;
  left: 0px;
  top: 0px;
  height: calc(var(--app-height) - 128px);
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 100;
`;

export const Detail = styled.p`
  color: ${({ theme }) => theme.white};
  max-width: 205px;
  margin: 8px 0px 0px 0px;
  text-align: center;
  font-size: 14px;
  font-weight: 300;
  line-height: 20px;
`;
