import styled, { css } from "styled-components";

export const StyledContact = styled.button<{ isDisabled?: boolean }>`
  background: transparent;
  width: 100%;
  display: flex;
  padding: 16px 24px;
  outline: none;
  border: none;
  text-align: left;
  border-bottom: 1px solid ${({ theme }) => theme.tertiary500};
  align-items: center;

  ${({ isDisabled }) =>
    !isDisabled &&
    css`
      cursor: pointer;
    `}
`;

export const Name = styled.p`
  font-weight: 300;
  color: ${({ theme }) => theme.neutral500} !important;
  font-family: inherit !important;
  font-size: 16px;
  line-height: 24px;
  margin: 0px;
  max-width: calc(100% - 42px);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const Details = styled.span`
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: ${({ theme }) => theme.neutral300};
`;

export const Phone = styled.div`
  width: 40px;
  min-width: 40px;
  height: 40px;
  border: 1px solid ${({ theme }) => theme.tertiary500};
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ContactInfo = styled.div`
  flex-grow: 1;
  max-width: calc(100% - 42px);
`;
