import styled, { css } from "styled-components";

export const ControlsSectionDiv = styled.section`
  display: flex;
  justify-content: space-between;
  padding: 40px 24px;
  background: ${({ theme }) => theme.neutral500};
`;

export const ControlsDiv = styled.div`
  display: flex;
`;

export const ControlButton = styled.button<{
  danger?: boolean;
  isDisabled?: boolean;
  unavailable?: boolean;
  withPoorConnection?: boolean;
}>`
  height: 48px;
  width: 48px;
  background-color: white;
  border-radius: 50%;
  border: none;
  margin-right: 24px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;

  &:last-of-type {
    margin-right: 0px;
  }

  ${({ danger }) =>
    danger &&
    css`
      background: red;
      color: white;
    `}

  ${({ isDisabled }) =>
    isDisabled &&
    css`
      background: rgba(0, 0, 0, 0.8);
    `}

	${({ unavailable }) =>
    unavailable &&
    css`
      cursor: not-allowed;
    `}

    ${({ withPoorConnection }) =>
    withPoorConnection &&
    css`
      background: ${({ theme }) => theme.neutral700};
      cursor: not-allowed;
    `}
`;
