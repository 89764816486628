import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Button,
  ErrorMessage,
  Header,
  IconClockFast,
  MessageBanner,
  Modal,
} from "../../components";
import { TestId, environment, metricEvents, surveyUrl } from "../../utils";
import { ModalContent } from "../../App.styles";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useHeap } from "../../hooks";

interface Props {
  isTimestampError?: boolean;
}

const SessionError: FC<Props> = ({ isTimestampError }) => {
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const { track } = useHeap();

  useEffect(() => {
    if (searchParams.has("requestFeedback")) {
      if (environment.displayFeedback) {
        setShowModal(true);
      }
      searchParams.delete("requestFeedback");
      navigate(
        {
          pathname: `.`,
          search: "",
        },
        { replace: true }
      );
    }
  }, [searchParams, setSearchParams, navigate]);

  useEffect(() => {
    if (isTimestampError) {
      track(metricEvents.timestampError);
    }
  }, [isTimestampError, track]);

  return (
    <main>
      <Header />
      <MessageBanner message={t("session_error.info")} />
      <ErrorMessage
        title={t("session_error.title")}
        description={t("session_error.subtitle")}
        testId={TestId.SessionError}
      >
        <IconClockFast />
      </ErrorMessage>
      <Modal
        isOpen={showModal}
        header={t("feedback_modal.title")}
        content={
          <ModalContent>
            <h3>{t("feedback_modal.title")}</h3>
            <p>{t("feedback_modal.description")}</p>
          </ModalContent>
        }
        bottomContent={
          <>
            <Button
              fullWidth
              onClick={() => {
                setShowModal(false);
                window.open(surveyUrl, "_blank");
              }}
            >
              {t("feedback_modal.leave_feedback")}
            </Button>
            <Button
              variant="secondary"
              noBorders
              fullWidth
              onClick={() => setShowModal(false)}
            >
              {t("feedback_modal.skip")}
            </Button>
          </>
        }
      />
    </main>
  );
};

export default SessionError;
