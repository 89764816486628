import { HTMLAttributes } from "react";
import {
  StyledContact,
  Name,
  Details,
  Phone,
  ContactInfo,
} from "./Contact.styles";
import { IconPhone } from "../Icons";
import { TestId } from "../../utils/testId";
import { PRIMARY_200, PRIMARY_500 } from "../../styles/colors";
import { Resident } from "../../api";

type ContactProps = {
  resident: Resident;
  onClick?: (res: Resident) => void;
  isDisabled?: boolean;
} & HTMLAttributes<HTMLButtonElement>;

const Contact = ({
  onClick,
  children,
  isDisabled,
  resident,
  ...props
}: ContactProps) => {
  const handleClick = () => {
    if (isDisabled) {
      return;
    }
    onClick?.(resident);
  };

  return (
    <StyledContact
      data-testid={TestId.Contact}
      onClick={handleClick}
      isDisabled={isDisabled}
      {...props}
    >
      <ContactInfo>
        <Name>{resident.name}</Name>
        {resident.unit && <Details>{resident.unit}</Details>}
      </ContactInfo>
      <Phone>
        <IconPhone
          width={15}
          height={15}
          fill={isDisabled ? PRIMARY_200 : PRIMARY_500}
        />
      </Phone>
    </StyledContact>
  );
};

export default Contact;
