import { FC } from "react";
import { useTranslation } from "react-i18next";
import { TestId } from "../../utils";
import { ErrorMessage, Header, IconQuestion } from "../../components";

const ValidationError: FC = () => {
  const { t } = useTranslation();

  return (
    <main>
      <Header />
      <ErrorMessage
        title={t("validation_error.title")}
        description={t("validation_error.subtitle")}
        testId={TestId.ValidationError}
      >
        <IconQuestion></IconQuestion>
      </ErrorMessage>
    </main>
  );
};

export default ValidationError;
