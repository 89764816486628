import { useCallback } from "react";
import { Heap } from "../types";
import { PENDING_METRICS_KEY, environment } from "../utils";
import { useLogger } from "./useLogger";

export const track2 = (e: string, loggerInfo: any, properties?: Object) => {
  // Retrieve any metrics pending for this session
  const pendingMetrics = sessionStorage.getItem(PENDING_METRICS_KEY);
  let parsedPendingMetrics = pendingMetrics && JSON.parse(pendingMetrics);
  if (!parsedPendingMetrics) {
    parsedPendingMetrics = [];
  }

  // Log events to CloudWatch even if heap has not been loaded
  loggerInfo(`Heap event: ${e}`, properties);
  if (!window.heap || !window.heap.loaded) {
    // Add new metric to pending for later
    parsedPendingMetrics.push({ e, properties });
    sessionStorage.setItem(
      PENDING_METRICS_KEY,
      JSON.stringify(parsedPendingMetrics)
    );
    return;
  }

  // Track each pending with Heap now that it's loaded
  parsedPendingMetrics.forEach((metric: any) => {
    window.heap.track(metric.e, metric.properties);
  });

  // Clear pending items
  sessionStorage.setItem(PENDING_METRICS_KEY, JSON.stringify([]));

  window.heap.track(e, properties);
};

export const useHeap = () => {
  const { heapId } = environment;
  const { info: loggerInfo } = useLogger();

  const load = useCallback(() => {
    if (window.heap && window.heap.loaded) {
      return;
    }

    if (window.heap && heapId) {
      window.heap.load(heapId, {
        rewrite: rewriteFunc,
        disableTextCapture: true,
      });
    }
  }, [heapId]);

  const track: Heap["track"] = useCallback(
    (e: string, properties: Object = {}) => {
      track2(e, loggerInfo, properties);
    },
    [loggerInfo]
  );

  return {
    load,
    track,
  };
};

interface RewriteProps {
  event_properties: Record<string, any>;
  pageview_properties: Record<string, any>;
  session_properties: Record<string, any>;
}

const rewriteFunc = (props: RewriteProps) => {
  const { event_properties, pageview_properties, session_properties } = props;
  let eventProperties, pageviewProperties, sessionProperties;

  if (event_properties) {
    const { href, hierarchy } = event_properties;

    eventProperties = {
      ...event_properties,
      ...(href && {
        href: applyRegex(href),
      }),
      ...(hierarchy &&
        hierarchy.indexOf("[href") > -1 && {
          hierarchy: applyRegex(hierarchy),
        }),
    };
  }

  if (pageview_properties) {
    const { path, hash, previous_page } = pageview_properties;

    pageviewProperties = {
      ...pageview_properties,
      ...(path && { path: applyRegex(path) }),
      ...(hash && { hash: applyRegex(hash) }),
      ...(previous_page && {
        previous_page: applyRegex(previous_page),
      }),
    };
  }

  if (session_properties) {
    const { path, hash, referrer } = session_properties;

    sessionProperties = {
      ...session_properties,
      ...(path && { path: applyRegex(path) }),
      ...(hash && { hash: applyRegex(hash) }),
      ...(referrer && { referrer: applyRegex(referrer) }),
    };
  }

  return {
    ...props,
    ...(eventProperties && { event_properties: eventProperties }),
    ...(pageviewProperties && { pageview_properties: pageviewProperties }),
    ...(sessionProperties && { session_properties: sessionProperties }),
  };
};

const applyRegex = (str: string | undefined) => {
  return str?.replace(/((event\/))([A-Za-z0-9_@.-]+?)(?=\/|\?|$)/g, "$1hidden");
};
export default useHeap;
