import styled, { css } from "styled-components";
import LoadingIndicator from "../LoadingIndicator/LoadingIndicator";

export const collapseTime = 500;

export const StyledGuestMedia = styled.section<{
  fullHeight?: boolean;
  thumbnail?: boolean;
  isCollapsed?: boolean;
}>`
  background: ${({ theme }) => theme.black65};
  width: 100%;
  display: flex;
  justify-content: center;
  border-radius: 4px;
  overflow: hidden;

  ${({ thumbnail, isCollapsed }) =>
    thumbnail &&
    css`
      position: absolute;
      top: 24px;
      left: 24px;
      width: 160px;
      height: 215px;
      background-color: ${({ theme }) => theme.black};
      max-height: ${isCollapsed ? "32px" : "500px"};
      max-width: ${isCollapsed ? "80px" : "500px"};
      transition: max-height ${collapseTime}ms ease-in-out,
        max-width ${collapseTime}ms ease-in-out;

      video {
        border-radius: 4px;
      }
    `}

  ${({ fullHeight }) =>
    fullHeight &&
    css`
      height: calc(var(--app-height) - 128px);
    `}
`;

export const StyledWebCam = styled.video<{ isMirrored: boolean }>`
  object-fit: cover;

  &.hide {
    display: none;
  }

  ${({ isMirrored }) =>
    isMirrored &&
    css`
      transform: scaleX(-1);
    `}
`;

export const CameraDisabled = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  background: ${({ theme }) => theme.neutral500};
  border-radius: 4px;
`;

export const Box = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  width: 100px;
  border-radius: 100%;
  background: ${({ theme }) => theme.secondary500};

  > span {
    font-weight: bold;
    font-size: 1.625rem;
    line-height: 1.625rem;
    color: ${({ theme }) => theme.white};
  }
`;

export const Loader = styled(LoadingIndicator)`
  height: 100%;
  width: 100%;
  padding: 0px;
`;

export const CameraUnavailable = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 42px 22px;

  > p {
    margin: 0px;
    color: ${({ theme }) => theme.neutral200};
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
  }

  > svg {
    margin-bottom: 16px;
  }
`;

export const YouOverlay = styled.section<{ isCollapsed?: boolean }>`
	position: absolute;
	bottom: 0px;
	background: rgba(0, 0, 0, 0.85);
	width: 100%;
	transition: max-height ${
    collapseTime / 2
  }ms ease-in-out, background ${collapseTime}ms ease-in-out;

	span:not(:first-child) {
		margin-left: 8px;
	}

  z-index: 10;
  
  ${({ isCollapsed }) =>
    css`
      height: 32px;
      max-height: ${isCollapsed ? "100px" : "20px"};
    `}

	${({ isCollapsed }) =>
    isCollapsed &&
    css`
      background: ${({ theme }) => theme.neutral600};
      border-radius: 4px;
      display: flex;
      justify-content: center;
      align-items: center;
    `}
}
`;
