import { FC } from "react";
import { Banner } from "./MessageBanner.styles";

interface MessageBannerProps {
  message: string;
}

export const MessageBanner: FC<MessageBannerProps> = ({ message }) => {
  return (
    <Banner>
      <p>{!message ? "" : message}</p>
    </Banner>
  );
};

export default MessageBanner;
