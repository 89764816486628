import Webcam from "react-webcam";
import styled, { css } from "styled-components";
import GuestMedia from "../GuestMedia/GuestMedia";

export const CallingBox = styled.section`
  background: ${({ theme }) => theme.neutral500};
  min-height: calc(var(--app-height) - 128px);
  display: flex;
  position: relative;
`;

export const CallingUser = styled.header`
  border-bottom: 1px solid ${({ theme }) => theme.neutral500};
  color: ${({ theme }) => theme.white};
  padding: 20px 24px;
  display: flex;
`;

export const UserData = styled.div`
  margin-left: 16px;

  > p {
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    margin: 0px;
  }
  > span {
    font-weight: 300;
    font-size: 14px;
    line-height: 20px;
  }
`;

export const StyledWebCam = styled(Webcam)`
  flex: 1;
  object-fit: cover;
  max-width: 100vw;
  min-height: calc(var(--app-height) - 128px);
`;

export const CameraDisabledBox = styled.div`
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  > p {
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    margin-top: 20px;
    margin-bottom: 16px;
    color: ${({ theme }) => theme.white};
    text-align: center;
    padding: 0px 16px;
  }
`;

export const Button = styled.button`
  padding: 6px 16px;
  font-weight: 700;
  font-size: 18px;
  line-height: 28px;
  background: #ffffff;
  border-radius: 8px;
  border: transparent;
  color: ${({ theme }) => theme.primary};
`;

export const GuestMediaFull = styled(GuestMedia)`
  max-height: calc(var(--app-height) - 128px);
`;

export const WebCamOverlay = styled.section<{
  camUnavailable?: boolean;
  camDisabled?: boolean;
}>`
  background: rgba(0, 0, 0, 0.65);
  min-height: calc(var(--app-height) - 128px);
  height: 100%;
  width: 100%;
  position: absolute;
  display: flex;
  flex-direction: column;

  ${({ camUnavailable, theme }) =>
    camUnavailable &&
    css`
      background: ${theme.neutral500};
    `}

  ${({ camDisabled }) =>
    camDisabled &&
    css`
      background: transparent;
    `}
`;
